<template>
  <section>
    <div style="display: flex" class="mt-6 mb-2">
      <h4 class="title is-4">Events</h4>
      <transition name="fade">
        <download-csv v-if="exportData && exportData.length > 0" class="ml-auto"
                      :data="exportData"
                      :name="exportFileName">
          <b-button type="is-success" icon-left="download">
            Export CSV
          </b-button>
        </download-csv>
      </transition>

      <transition name="fade">
        <download-file ref="downloadVideos" @onDownloadClicked="onDownloadVideosClicked" class="ml-2" v-if="checkedRows.length > 0">Download snippets
        </download-file>
      </transition>

    </div>
    <div class="box p-4 mb-6">
      <b-table
          key="incident_table"
          ref="table"
          :data="incidentTableData"
          :checked-rows.sync="checkedRows"
          checkable
          checkbox-position="left"
          checkbox-type="is-primary"
          hoverable
          paginated
          per-page="5"
          :current-page.sync="currentPage"
          :pagination-simple="false"
          pagination-position="bottom"
          default-sort-direction="asc"
          :pagination-rounded="false"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          @sort="sortClicked"
          default-sort="user.first_name"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          @page-change="onPageChanged"
          :page-input="false">
        <b-table-column field="eventId" label="EVENT ID">
          <template v-slot:header="{ column }">
            <table-header :column="column" element-id="223423" header-type="none"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            <b-button type="is-ghost" :disabled="selectedEventId === props.row.eventId"  @click="loadEvent(props.row)">{{ props.row.eventId }}</b-button>
          </template>
        </b-table-column>
        <b-table-column field="date" label="DATE" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="date" tooltip="Filter date" element-id="254788453"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ getDate(props.row.date) }}
          </template>
        </b-table-column>
        <b-table-column field="time" label="TIME">
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="time" tooltip="Filter time" element-id="25444"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ getTime(props.row.date) }}
          </template>
        </b-table-column>
        <b-table-column field="risk" label="RISK" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="dropdown"
                          element-id="223422"
                          :options="['Severe', 'Moderate', 'Low']"
                          tooltip="Filter risk"
                          :table-header-data="tableHeaderData"
                          @onSort="onSort"
            ></table-header>
          </template>
          <template v-slot="props">
            <div :class="getRiskColor(props.row.risk)" class="has-text-weight-medium">{{
                props.row.risk
              }}
            </div>
          </template>
        </b-table-column>
        <b-table-column field="dca" label="CRASH TYPE" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter CRASH TYPE" element-id="4674434"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.dca }}
          </template>
        </b-table-column>
        <b-table-column field="pet" label="PET" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter PET" element-id="4674435"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.pet ? props.row.pet >= 0.0 ? props.row.pet + ' seconds' : 'N/A' : 'N/A' }}
          </template>
        </b-table-column>
        <b-table-column field="type" label="EVENT TYPE" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="dropdown"
                          element-id="2234297d7adga2"
                          :options="eventTypes"
                          tooltip="Filter Event Type"
                          :table-header-data="tableHeaderData"
                          @onSort="onSort"
            ></table-header>
          </template>
          <template v-slot="props">
            {{ props.row.type ? props.row.type : 'N/A' }}
          </template>
        </b-table-column>
        <b-table-column field="userTypes" label="USER TYPES">
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter user types"
                          element-id="4674438" :table-header-data="tableHeaderData"
                          @onSort="onSort"></table-header>
          </template>
          <template v-slot="props">
            {{ props.row.userTypes }}
          </template>
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {getDate, getRiskRatingClass, getTime} from "@/utils/utils";
import DownloadFile from "@/components/download-file/download-file.vue";
import TableHeader from "@/components/table-header/table-header.vue";

export default {
  name: "incidents-table",
  components: {DownloadFile, TableHeader},
  props:{
    openNewPage: {
      type: Boolean,
      required: true
    },
    selectedEventId:{
      type:String,
      default: ''
    }
  },
  data(){
    return{
      checkedRows:[],
      currentPage: 1
    }
  },
  mounted() {
    this.currentPage = this.pageNumber
  },
  methods:{
    ...mapActions({
      setTableHeaderData: 'safetyModule/setTableHeaderData',
      fetchVideoUrls: 'safetyModule/getBulkVideoPresignedUrls',
      setTablePage: 'safetyModule/setTablePage',
    }),
    loadEvent(event) {
      console.log('Event:', event)
      let projectId = this.selectedProject._id
      let dataDeviceId = this.selectedDataDevice.id
      if(event.userTypes.includes('Undefined') || event.userTypes == null){
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Road user/users is Undefined',
          type: 'is-danger'
        })
        return
      }

      console.log('Router call: ', this.$router)

/*      if(this.openNewPage){
        this.$router.push({name: 'event-details', params: params})
      }else{
        this.$router.replace({name: 'event-details', params: params})
      }*/

      if(this.openNewPage){
        const params = {
          eventId: event.eventId,
          projectId: projectId,
          dataDeviceId: dataDeviceId,
          openNewPage: this.openNewPage,
          pageNumber: this.currentPage
        }
        this.$emit('onLoadEvent', params)
      }else{
        const params = {
          eventId: event.eventId,
          projectId: projectId,
          dataDeviceId: dataDeviceId,
          pageNumber: this.currentPage
        }
        this.$router.replace({name: 'event-details', params: params})
      }


    },
    sortClicked(field, order){
      console.log('Sorted: ', field, order)
      let tableHeaderData = this.tableHeaderData
      tableHeaderData.activeSorted = {field: field, order:order}
      this.setTableHeaderData(tableHeaderData)
    },
    onSort(tableHeaderData){
      this.setTableHeaderData(tableHeaderData)
    },
    getDate(date){
      return getDate(date)
    },
    getTime(date){
      return getTime(date)
    },
    getRiskColor(risk){
      return getRiskRatingClass(risk)
    },
    async onDownloadVideosClicked(){
      this.isLoading = true
      let snippetIds = this.checkedRows.map(row => row.videoSnippetId)
      console.log('On download videos clicked, snippet ids:', snippetIds)
      let response = await this.fetchVideoUrls(snippetIds)
      console.log('Fetch response:', response)
      this.isLoading = false

      await this.$refs.downloadVideos.downloadFiles(response, 'videos')
    },
    onPageChanged(pageNum){
      console.log('On page changed', pageNum)
      this.setTablePage(pageNum)
    }
  },
  computed: {
    ...mapState({
      selectedProject: state => state.safetyModule.selectedProject,
      selectedDataDevice: state => state.safetyModule.selectedDataDevice,
      tableHeaderData: state => state.safetyModule.tableHeaderData
    }),
    ...mapGetters({
      incidentTableData: 'safetyModule/getIncidentTable',
      averageValues: 'safetyModule/getAverageValues',
      getFilteredSites: 'safetyModule/getFilteredSites',
      pageNumber: 'safetyModule/getCurrentTablePage',
      allTableData: 'safetyModule/getTableData'
    }),
    exportData(){
      return  this.incidentTableData.map(dt=>({
        EVENT_ID: dt.eventId,
        DATE: this.getDate(dt.date),
        TIME: this.getTime(dt.date),
        RISK: dt.risk,
        DCA: dt.dca,
        PET: dt.pet ? dt.pet >= 0.0 ? dt.pet : 'N/A' : 'N/A',
        TTC: dt.ttc,
        KINETIC_ENERGY: dt.kineticEnergy,
        USER_TYPES: dt.userTypes,
      }))
    },
    exportFileName(){
      if(this.selectedDataDevice){
        return `${this.selectedDataDevice.name}.csv`
      }else{
        return 'no_name.csv'
      }
    },
    eventTypes(){
      if(this.allTableData && this.allTableData.length > 0){
        let types = this.allTableData.map(data => data.type)
        if(types.length > 0){
          let uniqueItems = [...new Set(types)]
          console.log('UNIQUE', uniqueItems)
          return uniqueItems
        }
      }

      return []
    }
  },
  watch:{
    pageNumber(){
      if(this.pageNumber !== this.currentPage){
        this.currentPage = this.pageNumber
      }
    }
  }
}
</script>

<style scoped>

</style>